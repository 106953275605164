const siteData = {
  title: 'Kendall Whitman',
  subtitle: 'Fullstack Developer',
  email: 'kendall@kendallwhitman.dev',
  resumes: [
    // {
    //   link: 'https://next.kendallwhitman.dev/s/WoRj9gLyYei5qQ7/download',
    //   name: 'PDF',
    // },
    // {
    //   link: 'https://next.kendallwhitman.dev/s/K5gwAiQJSyWB32E/download',
    //   name: 'Word',
    // },
  ]
}

export default siteData
